import { groupBy, mapValues } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useFetch } from '../api/ApiProvider';
import { DashboardContext, useDomains, useWidgets } from '../dashboard/DashboardProvider';
import { useActiveLanguageCode } from '../i18n/LanguageProvider';
import { useLocalStorage } from '../shared/hooks';
import { clearRegionalFilterValues, useAllWidgetPreferences } from './widget';

export const useIsWidgetActive = () => {
  const [selectedWidgets] = useSelectedWidgets();

  return useCallback(widgetId => selectedWidgets.includes(widgetId), [selectedWidgets]);
};

export const useToggleWidget = () => {
  const isWidgetActive = useIsWidgetActive();
  const activateWidgets = useActivateWidgets();
  const deactivateWidgets = useDeactivateWidgets();

  return useCallback(
    widgetId => (isWidgetActive(widgetId) ? deactivateWidgets(widgetId) : activateWidgets(widgetId)),
    [isWidgetActive, deactivateWidgets, activateWidgets]
  );
};

export const useDeactivateWidgets = () => {
  const [selectedWidgets, setSelectedWidgets] = useSelectedWidgets();

  return useCallback(
    (...widgetIds) => setSelectedWidgets(selectedWidgets.filter(id => !widgetIds.includes(id))),
    [selectedWidgets, setSelectedWidgets]
  );
};

export const useActivateWidgets = () => {
  const [selectedWidgets, setSelectedWidgets] = useSelectedWidgets();

  const widgets = useWidgets();

  return useCallback(
    (...widgetIds) => {
      // Create a new set of selected widgets
      const updatedSelection = new Set([...selectedWidgets, ...widgetIds]);

      // Sort based on the original order in `widgets`
      const sortedSelection = widgets.map(widget => widget.id).filter(id => updatedSelection.has(id)); // Keep only the ones that are selected

      setSelectedWidgets(sortedSelection);
    },
    [selectedWidgets, setSelectedWidgets]
  );
};

export const useIsDomainActive = () => {
  const domains = useDomainsById();
  const isWidgetActive = useIsWidgetActive();

  return useCallback(
    domainId => domains[domainId].widgets.some(widget => isWidgetActive(widget.id)),
    [domains, isWidgetActive]
  );
};

export const useToggleDomain = () => {
  const isDomainActive = useIsDomainActive();
  const activateDomain = useActivateDomain();
  const deactivateDomain = useDeactivateDomain();

  return useCallback(
    domainId => (isDomainActive(domainId) ? deactivateDomain(domainId) : activateDomain(domainId)),
    [isDomainActive, activateDomain, deactivateDomain]
  );
};

export const useActivateDomain = () => {
  const domains = useDomainsById();
  const activateWidgets = useActivateWidgets();

  return useCallback(
    domainId => activateWidgets(...domains[domainId].widgets.map(widget => widget.id)),
    [domains, activateWidgets]
  );
};

export const useDeactivateDomain = () => {
  const domains = useDomainsById();
  const deactivateWidgets = useDeactivateWidgets();

  return useCallback(
    domainId => deactivateWidgets(...domains[domainId].widgets.map(widget => widget.id)),
    [domains, deactivateWidgets]
  );
};

export const useDomainsById = () => {
  const domains = useDomains();

  return useMemo(() => mapValues(groupBy(domains, 'id'), ([d]) => d), [domains]);
};

export const useSelectedWidgets = () => {
  const {
    selectedWidgets: [selectedWidgets = [], setSelectedWidgets],
  } = useContext(DashboardPreferencesContext);

  return [selectedWidgets, setSelectedWidgets];
};

export const useRegion = () => useContext(DashboardPreferencesContext).region;

export const useIsFirstVisit = () => {
  const { selectedWidgets, isFirstVisit } = useContext(DashboardPreferencesContext);

  return isFirstVisit === true || selectedWidgets[0] === undefined;
};

const nop = () => {};

const DashboardPreferencesContext = React.createContext({
  selectedWidgets: [[], nop],
  region: [undefined, nop],
});

export const ApiDashboardPreferencesProvider = ({ children }) => {
  const fetch = useFetch();
  const languageCode = useActiveLanguageCode();
  const { dashboardId, dashboard, setDashboard } = useContext(DashboardContext);

  const { selectedWidgets = [], regions = [] } = dashboard;

  const setSelectedWidgets = selectedWidgetIds =>
    fetch(`/v2/dashboard/${dashboardId}/widgets`, {
      method: 'PATCH',
      body: { selectedWidgets: selectedWidgetIds, language: languageCode },
    }).then(setDashboard);

  const region = regions
    .filter(r => r.selected)
    .map(r => r.id)
    .shift();
  const setRegion = region =>
    fetch(`/v2/dashboard/${dashboardId}/region`, {
      method: 'PATCH',
      body: { language: languageCode, region: parseInt(region) },
    }).then(setDashboard);

  const value = {
    selectedWidgets: [selectedWidgets, setSelectedWidgets],
    region: [region, setRegion],
    isFirstVisit: dashboard.firstVisit,
  };

  return <DashboardPreferencesContext.Provider value={value}>{children}</DashboardPreferencesContext.Provider>;
};

export const MyDashboardPreferencesProvider = ({ children }) => {
  const fetch = useFetch();
  const languageCode = useActiveLanguageCode();
  const {
    dashboardId,
    dashboard: { selectedWidgets = [], firstVisit },
    setDashboard,
  } = useContext(DashboardContext);

  const setSelectedWidgets = selectedWidgetIds =>
    fetch(`/v2/dashboard/${dashboardId}/widgets`, {
      method: 'PATCH',
      body: { selectedWidgets: selectedWidgetIds, language: languageCode },
    }).then(setDashboard);

  const value = {
    selectedWidgets: [selectedWidgets, setSelectedWidgets],
    region: [null, nop],
    isFirstVisit: firstVisit,
  };

  return <DashboardPreferencesContext.Provider value={value}>{children}</DashboardPreferencesContext.Provider>;
};

export const LocalStorageDashboardPreferencesProvider = ({ children }) => {
  const { dashboardId } = useContext(DashboardContext);

  const setAllWidgetPreferences = useAllWidgetPreferences()[1];

  const selectedWidgets = useLocalStorage(`dashboard/${dashboardId}/selectedWidgets`);
  const [region, setRegion] = useLocalStorage(`v2/dashboard/${dashboardId}/region`);

  const value = {
    selectedWidgets,
    region: [
      region,
      (...args) => {
        setRegion(...args);
        setAllWidgetPreferences(clearRegionalFilterValues);
      },
    ],
  };

  return <DashboardPreferencesContext.Provider value={value}>{children}</DashboardPreferencesContext.Provider>;
};

export const FixedDashboardPreferencesProvider = ({ selectedWidgets, region, children }) => (
  <DashboardPreferencesContext.Provider value={{ selectedWidgets: [selectedWidgets, nop], region: [region, nop] }}>
    {children}
  </DashboardPreferencesContext.Provider>
);

export const clearDashboardPreferences = () =>
  Object.keys(localStorage)
    .filter(key => key.match(/^(?:v2\/)?dashboard\/[0-9]/))
    .forEach(key => localStorage.removeItem(key));
